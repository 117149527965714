import { Controller } from 'stimulus'

export default class extends Controller {
  static target =
   [
    'dropDown'
   ]
  connect(){}

  toggleFilter(event) {

    let filter = event.currentTarget.dataset.filterType
    let value = event.currentTarget.dataset.filterValue

    var searchParams = new URLSearchParams(window.location.search)
    if (filter == 'campaign') {
        searchParams.delete('exit_delivery')
        searchParams.delete('status')
      }
    if (searchParams.get(filter) == value) {
      searchParams.delete(filter)
    }
    else
      searchParams.set(filter, value)

    searchParams.delete('page')
    window.location.search = searchParams.toString()
  }
}
