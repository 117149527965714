import { Controller } from 'stimulus'
import Ajax from '../http/ajax'

export default class extends Controller {
  static targets = [
    'name',
    'reference',
    'percentages',
    'dropdown',
    'unequalPercentagesText',
    'form',
    'updateButton',
    'editButton',
    'deDupeDaysInput',
    'leadsCount'
  ]

  static values = {
    statsUrl: String,
    exitDeliveriesCountUrl: String,
    leadsCountUrl: String
  }

  connect() {
    this.loadStats()
    this.loadExitDeliveryCount()
    this.loadLeadsCount()
  }

  setReference() {
    this.referenceTarget.value = this.nameTarget.value.toUpperCase().replaceAll(' ', '-')
  }

  changeDivider(event) {
    if (event.currentTarget.value == 'unequally') {
      this.percentagesTarget.hidden = false
    }
    else {
      this.percentagesTarget.hidden = true
    }
    this.enableDistributionChange()
  }

  updateDivideSettings(event) {
    if (this.verifyPercentages() || this.dropdownTarget.value != 'unequally') {
      let data = new FormData(this.formTarget)
      Ajax.post(
        window.location.href,
        data,
        (response) => {
          this.disablePercentagesEditable(true)
          this.updateButtonTarget.hidden = true
          this.editButtonTarget.hidden = false
        },
        (err) => {
          console.log(err)
        },
        this
      )
    }
    else {
      this.unequalPercentagesTextTarget.hidden = false
    }
  }

  verifyPercentages() {
    var sum = 0
    for (var target of this.targets.findAll('percentage')) {
      sum = sum + parseInt(target.value)
    }
    return (sum == 100 ? true : false)
  }

  enableDistributionChange() {
    this.disablePercentagesEditable(false)
    this.updateButtonTarget.hidden = false
    this.editButtonTarget.hidden = true
  }

  disablePercentagesEditable(value) {
    this.dropdownTarget.disabled = value
    if (this.dropdownTarget.value == 'unequally')
      for (var target of this.targets.findAll('percentage')) {
        target.disabled = value
      }
  }

  toggleDeDupe(event) {
    if (event.currentTarget.checked) {
      this.deDupeDaysInputTarget.classList.remove('d-none')
    } else {
      this.deDupeDaysInputTarget.classList.add('d-none')
    }
  }

  loadStats() {
    if (this.hasStatsUrlValue) {
      Ajax.get(
        this.statsUrlValue,
        (response) => {
          this.replace(this.element, response.html)
        },
        (err) => {
          console.log(err)
        },
        this
      )
    }
  }

  loadExitDeliveryCount() {
    if (this.hasExitDeliveriesCountUrlValue) {
      Ajax.get(
        this.exitDeliveriesCountUrlValue,
        (response) => {
          this.replace(this.element, response.html)
        },
        (err) => {
          console.log(err)
        },
        this
      )
    }
  }

  loadLeadsCount() {
    if (this.hasLeadsCountUrlValue) {
      Ajax.get(
        this.leadsCountUrlValue,
        (response) => {
          this.replace(this.leadsCountTarget, response.html)
        },
        (err) => {
          console.log(err)
        },
        this
      )
    }
  }

  replace(element, html) {
    let temp = document.createElement('div')
    temp.innerHTML = html

    if (element.parentNode) {
      element.parentNode.replaceChild(temp.firstChild, element)
    }
  }
}
