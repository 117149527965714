import { Controller } from 'stimulus'
import Ajax from '../http/ajax'

export default class extends Controller {
  static targets = 
    [
      'reference',
      'fieldsContainer',
      'rulesContainer',
      'form',
      'perMin',
      'perHour',
      'perDay',
      'fields',
      'addFieldButton',
      'deDupeDaysInput',
    ]

  connect() { }

  setReference() {
    this.referenceTarget.value = this.referenceTarget.value.toUpperCase().replaceAll(' ', '-')
  }

  adjustLimits(event) {
    if (event.currentTarget == this.perMinTarget) {
      this.perHourTarget.value = Math.ceil(this.perMinTarget.value * 60)
      this.perDayTarget.value = Math.ceil(this.perHourTarget.value * 9)
    }
    else if (event.currentTarget == this.perHourTarget) {
      this.perMinTarget.value = Math.ceil(this.perHourTarget.value / 60)
      this.perDayTarget.value = Math.ceil(this.perHourTarget.value * 9)
    }
    else {
      this.perHourTarget.value = Math.ceil(this.perDayTarget.value / 9)
      this.perMinTarget.value = Math.ceil(this.perHourTarget.value / 60)
    }
  }

  getRulesSettings() {
    var url = window.location.href + '/rules_settings'
    Ajax.get(
      url,
      (response) => {
        this.rulesContainerTarget.innerHTML = response.html
      },
      (err) => {
        console.log(err)
      }
    )
  }

  updateRules(event) {
    let data = new FormData(this.formTarget)
    Ajax.post(
      window.location.href + '/rules/' + event.currentTarget.dataset.value,
      data,
      (response) => {
        this.rulesContainerTarget.innerHTML = response.html
      },
      (err) => {
        console.log(err)
      },
      this
    )
  }

  getFieldsConfigurations() {
    var url = window.location.href + '/fields_configurations'
    Ajax.get(
      url,
      (response) => {
        this.fieldsContainerTarget.innerHTML = response.html
      },
      (err) => {
        console.log(err)
      }
    )
  }
  
  updateFields() {
    let data = new FormData(this.formTarget)
    Ajax.post(
      window.location.href,
      data,
      (response) => {
        this.fieldsContainerTarget.innerHTML = response.html
      },
      (err) => {
        console.log(err)
      },
      this
    )
  }

  changeName(event) {
    var element = event.currentTarget.parentNode
    while (element = element.nextElementSibling){
      element.firstChild.name = "exit_delivery[fields]["+ event.currentTarget.value +"][]"
    }
  }

  addField(event) {
    var url = window.location.href + '/new_field'
    Ajax.get(
      url,
      (response) => {
        this.addFieldButtonTarget.remove()
        this.fieldsTarget.innerHTML += response.html
      },
      (err) => {
        console.log(err)
      }
    )
  }

  toggleDeDupe(event) {
    if (event.currentTarget.checked) {
      this.deDupeDaysInputTarget.classList.remove('d-none')
    } else {
      this.deDupeDaysInputTarget.classList.add('d-none')
    }
  }
}
