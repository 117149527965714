import { Controller } from 'stimulus'
import { values } from 'underscore';
import Ajax from '../http/ajax'

export default class extends Controller {
  static targets = [
                    'todayLeads',
                    'totalLeads'
                    ]

  static values = { leadsUrl: String };

  connect() {
    this.fetchLeads(this.todayLeadsTarget, "today")
    this.fetchLeads(this.totalLeadsTarget, "total")
  }
  
  fetchLeads(el, stats) {
    Ajax.get(
      `${this.leadsUrlValue}?stats=${stats}`,
      (response) => {
        el.innerHTML = response.html
      },
      (err) => {
        console.log(err)
      } 
    )
  }
}
